const Article = (props) => {
    const {
        article
    } = props;

    const {title, pageTitle, paragraphs} = article;
    const content = paragraphs.map((item, index) => (
        <p key={index} className={item.class}>
            {item.imgUrl && <img src={item.imgUrl} alt="article-image"/>}
            {item.text && <span dangerouslySetInnerHTML={{__html: item.text}}/>}
            {
                item.list &&
                <ul className={item.list.class}>
                    {
                        item.list.items.map(listItem => (
                            <li>
                                {listItem}
                            </li>
                        ))
                    }
                </ul>}
        </p>
    ));

    return (
        <div className="article">
            <h4 className="underline">{pageTitle}</h4>
            <h3>{title}</h3>
            {content}
        </div>
    )
};

export default Article;
