export const INTERNATIONAL_COOPERATION_ARTICLE = {
    pageTitle: 'About Us',
    title: 'STATE VOCATIONAL EDUCATIONAL INSTITUTION «LVIV HIGHER VOCATIONAL SCHOOL OF COMPUTER TECHNOLOGIES and CONSTRUCTION»',
    paragraphs: [
        {
            class: 'article-image-left indent',
            imgUrl: 'image/cooperations/vpu.jpg',
            text: `<span class="strong">State Vocational Educational
                        Institution «Lviv Higher Vocational School
                        of Computer Technologies and
                        Construction»</span> is one of the leading
                    vocational school in IT field in the L’viv
                    region of Ukraine. It was founded in 1965.
                    Since 2022, Lviv Higher Vocational School
                    of Computer Technologies and Construction has been implementing a training
                    program Drone Operator for various target audiences with supporting of the United
                    Nations Development Programme and Department of Education and Science of the
                    Lviv regional State Administration. In 2023 there was created Digital Educational
                    Center for children of internally displaced person with supporting of the
                    international organization Save the children. The Educational Institution has
                    collaboration with IT companies.`
        },
        {
            class: 'indent',
            text: `State Vocational Educational Institution «Lviv Higher Vocational School of
                    Computer Technologies and Construction» carries out a lot of activities to inform
                    students and other IT devotees about the IT field. Namely, workshops, meetings
                    with famous people, training computer courses are systematically organized. In
                    general, the vocational school has successful experience in implementing projects
                    related to the digital competences development for pupils and teachers.`
        },
        {
            class: '',
            text: `<span class="strong">Teaching staff</span> is more than 60 people.`
        },
        {
            class: '',
            text: `<span class="strong">The contingent of students</span> amounted to 580 people on October 01, 2023-2024
                    academic year.`
        },
        {
            class: '',
            text: `<span class="strong">Educational area</span>`
        },
        {
            class: 'indent',
            text: `The Educational Institution has a license from the Ministry of Education and
                    Science of Ukraine for conducting educational activities at the level of Professional
                    Junior Bachelor’s and qualified worker’s degrees <a href="https://lvpuktb.com.ua/licenses">https://lvpuktb.com.ua/licenses</a>`
        },
        {
            class: 'indent',
            text: `The Educational Institution provides training in the following areas:`
        },
        {
            class: '',
            list: {
                class: 'indent-1',
                items: [
                    'Computer Technologies',
                    'Motor Vehicle Transport',
                    'Building and Civil Engineering'
                ]
            }
        },
        {
            class: '',
            text: `Educational Programs <a href="https://lvpuktb.com.ua/educational-programs">https://lvpuktb.com.ua/educational-programs</a>`
        },
        {
            class: '',
            text: `<span class="strong">STATUTE of State Vocational Educational Institution</span> <br/>
                    <a href="https://lvpuktb.com.ua/regulation">https://lvpuktb.com.ua/regulation</a>`
        },
        {
            class: '',
            text: `Internationals links <a href="https://lvpuktb.com.ua/our-partners">https://lvpuktb.com.ua/our-partners</a>`
        }
    ]
}
