import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const Library = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="бібліотека">
				<h4>				
					<a href="https://drive.google.com/file/d/1KmtWw2mePRtaSb5yatlc2fjI8Z283TJ-/view?usp=sharing" target="_blanck">
						Положення про бібліотеку ДПТНЗ "Львівське вище професійне училище комп'ютерних технологій та будівництва
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://lib.imzo.gov.ua/pdruchniki-ta-navchaln-posbniki-dlya-zdobuvachv-profesyno-profesyno-tekhnchno-osvti/
" target="_blanck">
						Підручники та навчальні посібники для здобувачів професійної (професійно-технічної) освіти
					</a>
				</h4>
				<br />
				<h3>				
						Електронні посібники, підручники для ФМБ спеціальності 274 "Автомобільний транспорт"
				</h3>
				<br />
				<h4>				
					<a href="https://document.kdu.edu.ua/monogr/2023_106.pdf
" target="_blanck">
						Автомобільні двигуни. Основи теорії двигунів внутрішнього згоряння. В.Ф. Шапко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://e-tk.lntu.edu.ua/pluginfile.php/17105/mod_resource/content/0/%D0%A2%D0%95%D0%90%20%28%D0%BB%D0%B5%D0%BA%D1%86%D1%96%D1%97%29.pdf
" target="_blanck">
						Технічна експлуатація автомобілів. В.Дембіцький, В.Павлюк
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://atm.vntu.edu.ua/subject/books/VTBPAT/Posibnyk.pdf
" target="_blanck">
						Виробничо-технічна база підприємства автомобільного транспорту. В.В.Біліченко, В.Л.Крещенецький
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://atm.vntu.edu.ua/subject/books/OTD/posOTD.pdf
" target="_blanck">
						Основи технічної діагностики колісних транспортних засобів. В.В.Біліченко, В.Л.Крещенецький
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://ir.lib.vntu.edu.ua/bitstream/handle/123456789/33877/51911.pdf?sequence=2&isAllowed=y
" target="_blanck">
						Автомобілі. Теорія експлуатаційних властивостей. В.В.Біліченко, О.Л.Добровольський
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://document.kdu.edu.ua/info_zab/275_1460.pdf
" target="_blanck">
						Транспортні енергетичні установки. О.М.Артюх, О.В.Дударенко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1jG6eCxQvr-yh9Ut2-3MW23ANIWtbB4OM/view
" target="_blanck">
						Технічна експлуатація і обслуговування автомобілів. О.А. Лудченко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://pdf.lib.vntu.edu.ua/books/2016/Ludchenko_2003_511.pdf
" target="_blanck">
						Технічне обслуговування і ремонт автомобілів. О.А. Лудченко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://pdf.lib.vntu.edu.ua/books/2016/Sazhko_2009.pdf
" target="_blanck">
						Електрообладнання автомобілів і тракторів. В.А. Сажко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://elartu.tntu.edu.ua/bitstream/123456789/18170/1/EEOA_Lect_%D0%86_FullText.pdf
" target="_blanck">
						Електронне та електричне обладнання автомобілів. Ю.І. Пиндус, Р.Р. Заверуха 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://epub.chnpu.edu.ua/jspui/bitstream/123456789/3282/1/%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D0%B8%20%D0%B4%D1%96%D0%B0%D0%B3%D0%BD%D0%BE%D1%81%D1%82%D0%B8%D0%BA%D0%B8%20%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%BE%D0%B1%D1%96%D0%BB%D1%8F.pdf
" target="_blanck">
						Основи діагностики автомобіля. В.С. Люлька, М.М. Коньок 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://document.kdu.edu.ua/info_zab/274_469.pdf
" target="_blanck">
						Теорія руху автомобіля. В.П. Волков, Г.Б. Вільський 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://ep3.nuwm.edu.ua/2215/1/037%20zah.pdf
" target="_blanck">
						Технологічне обладнання автотранспортних підприємств. Б. І. Червоний 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://elib.sclnau.com.ua/pdf/previewPDF/229
" target="_blanck">
						Гібридні автомобілі. О. В. Бажинов, О. П. Смирнов 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://lib.lntu.edu.ua/sites/default/files/2021-01/%D0%A2%D0%9E%20%D1%96%20%D0%A0%20%D0%90%D0%A2%D0%97%20-%20%D0%9D%D0%B0%D0%B2%D1%87%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D0%B9%20%D0%BF%D0%BE%D1%81%D1%96%D0%B1%D0%BD%D0%B8%D0%BA.pdf
" target="_blanck">
						Технічне обслуговування і ремонт АТЗ. О. В. Захарчук 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://library.kr.ua/wp-content/elib/chabannyi/Chabannyi_Remont_avto_kn1.pdf
" target="_blanck">
						Ремонт автомобілів. Книга 1. В. Я. Чабанний 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://lib.lntu.edu.ua/sites/default/files/2021-01/%D0%9E%D0%A2%D0%92%20%D1%96%20%D0%A0%D0%90%20-%20%D0%9D%D0%B0%D0%B2%D1%87%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D0%B9%20%D0%BF%D0%BE%D1%81%D1%96%D0%B1%D0%BD%D0%B8%D0%BA.pdf
" target="_blanck">
						Основи технології виробництва та ремонту автомобілів. О. В. Захарчук 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1MH5GUjqC5H9CEDXQjK7WQFqAPehaD0xz/view
" target="_blanck">
						Трактори і автомобілі. В. С. Бучок, В. Ф. Ясюк
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://library.kr.ua/wp-content/elib/chabannyi/Chabannyi_Pal_mast_Mater_kn1.pdf
" target="_blanck">
						Паливо-мастильні матеріали, технічні рідини та системи їх забезпечення. В. Я. Чабанний, С. О. Магопець
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://www.tsatu.edu.ua/tkm/wp-content/uploads/sites/11/144_posybnyk.pdf
" target="_blanck">
						Паливно-мастильні матеріали та інші експлуатаційні матеріали. І. М. Бендера, В. І. Дуганець
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://vodiy.ua/pdr/
" target="_blanck">
						Правила дорожнього руху
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://www.dgma.donetsk.ua/metod/opm/2019/konspekt%20lekc_j%20VSTV.pdf
" target="_blanck">
						Взаємозамінність, стандартизація та технічні вимірювання. А. П. Мартинов
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://www.dgma.donetsk.ua/metod/opm/2019/konspekt%20lekc_j%20VSTV.pdf
" target="_blanck">
						Взаємозамінність, стандартизація та технічні вимірювання. Л. О. Цвіркун, О.В. Омельченко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://www.dgma.donetsk.ua/metod/opm/2019/konspekt%20lekc_j%20VSTV.pdf
" target="_blanck">
						Взаємозамінність, стандартизація та технічні вимірювання. 2-ге видання. І. С. Сірий
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://elibrary.donnuet.edu.ua/2591/1/2022_NP_Tsvirkun_Omelchenko_Vzaemozaminist.pdf
" target="_blanck">
						Взаємозамінність, стандартизація та технічні вимірювання. Л. О. Цвіркун, О.В. Омельченко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://pdf.lib.vntu.edu.ua/books/2015/Baziev_2004_504.pdf
" target="_blanck">
						Взаємозамінність, стандартизація та технічні вимірювання. С. Д. Базієвський, В. Ф. Дмитришин
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://elib.sclnau.com.ua/pdf/previewPDF/181
" target="_blanck">
						Матеріалознавство і технологія конструкційних матеріалів. Р. Гуменюк
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://univer.nuczu.edu.ua/tmp_metod/924/MZTM_KONSP_LEK.pdf
" target="_blanck">
						Матеріалознавство та технологія матеріалів. В. О. Жовдак, К. П. Іванова
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1qAM03m4YQ9K8i2k7IvMkm0-LnnjeOkzs/view
" target="_blanck">
						Лабораторний практикум з технології конструкційних матеріалів і матеріалознавства. А. С. Опальчук, О. О. Котречко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://dspace.pnpu.edu.ua/bitstream/123456789/17044/3/%D0%A2%D0%B5%D1%85%D0%BD%D1%96%D1%87%D0%BD%D0%B0%20%D0%BC%D0%B5%D1%85%D0%B0%D0%BD%D1%96%D0%BA%D0%B0%202021.pdf
" target="_blanck">
						Технічна механіка. М. Л. Рябчиков, А. М. Хлопов
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://btpm.nmu.org.ua/ua/download/navch-posib/%D0%90%D0%BD%D1%82%D0%BE%D0%BD%D0%B5%D0%BD%D0%BA%D0%BE.%D0%9F%D0%B5%D1%80%D0%B3%D0%B0.%D0%A2%D0%B5%D1%85%D0%BC%D0%B5%D1%85.%D0%9F%D0%BE%D1%81%D1%96%D0%B1%D0%BD%D0%B8%D0%BA.pdf
" target="_blanck">
						Технічна механіка. Ю. С. Рудь, С. В. Філатов
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1TVwGOwj6UKUVmRknIg1CSSEKhYYSb0DO/view
" target="_blanck">
						Практикум з теоретичної механіки. С. І. Пастушенко, О. Г. Руденко
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://socrates.vsau.org/b04213/html/cards/getfile.php/32774.pdf
" target="_blanck">
						Нарисна геометрія та інженерна графіка. О. М. Джеджула
						</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/11l7sV_u38goxDiPhZhS6d97TWjx70SwE/view
" target="_blanck">
						Загальна електротехніка з основами автоматики. Т. В. Левченко, В.В. Хоменко
						</a>
				</h4>
				<br />
				<h4>				
					<a href="http://lib.kart.edu.ua/bitstream/123456789/2466/1/%D0%9D%D0%B0%D0%B2%D1%87%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D0%B9%20%D0%BF%D0%BE%D1%81%D1%96%D0%B1%D0%BD%D0%B8%D0%BA.pdf
" target="_blanck">
						Основи маркетингу та менеджменту. О І. Зоріна, А.О. Дергоусова
						</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1GEgvL8rkylJeEQoWh5d4CLZBMJVV-BFI/view
" target="_blanck">
						Вища математика. В І. Казановський, А.Г. Африканова
						</a>
				</h4>
				<br />
				<h4>				
					<a href="https://kpdi.edu.ua/biblioteka/%D0%9E/%D0%9E%D1%85%D0%BE%D1%80%D0%BE%D0%BD%D0%B0%20%D0%BF%D1%80%D0%B0%D1%86%D1%96%20%D0%9E%D0%B4%D0%B0%D1%80%D1%87%D0%B5%D0%BD%D0%BA%D0%BE%20%D0%9C.%D0%A1..pdf
" target="_blanck">
						Основи охорони праці. М. С. Одарченко, А. М. Одарченко
						</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/15qr2ltcpQ80RrC2wi6BzQKgFIP4JJNs7/view?usp=sharing" target="_blanck">
						Автомобільна промисловість
						</a>
				</h4>
				<br />
				<h4>				
					<a href=" https://drive.google.com/file/d/1Q-gWnRY55guTSxyuWR3fVLe92_XRO-Vg/view?usp=sharing" target="_blanck">
					Англійська мова для студентів автомобільного напряму
						</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ff9XmjX1cchqxbjRJwLpJSKPdLN516ja/view?usp=drive_link" target="_blanck">
					English for business communication
					</a>
				</h4>
				<br />
				<h4>				
					<a href=" https://westudents.com.ua/knigi/627-flosofya-petrushenko-vl.html" target="_blanck">
						Філософія
					</a>
				</h4>
				<br />
				<h4>				
					<a href=" http://surl.li/lvrgpo" target="_blanck">
						Основи трудового законодавства
					</a>
				</h4>
				


				<br />
				<h3>	
							
						Електронні посібники, підручники для ФМБ спеціальності 192 "Будівництво та цивільна інженерія"
				</h3>
				<br />
				<h4>				
					<a href="https://vukladach.pp.ua/MyWeb/manual/pidruchnuku13122023/Budivelni%20konstrykciy/Golovna/Golovna.htm
" target="_blanck">
						Будівельні конструкції
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1vVQ_sF3qT0mL1re6dR2RqLrRnQzDZOtl/view
" target="_blanck">
						Будівельні конструкції (навчальний посібник). А. Петриковська
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://vukladach.pp.ua/MyWeb/manual/pidruchnuku13122023/Informatsiyni%20tekhnolohiyi/Zmist/Zmist.htm
" target="_blanck">
						Інформаційні технології
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1MGlMRWEbunJYl-E2uzSl4y43qFABC1yq/view
" target="_blanck">
						Основи екології
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1854Whb9aJl-7WH-bob9btILKr97Sq9xv/view
" target="_blanck">
						Сільськогосподарські будівлі і споруди
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13KuN1zPFfavKAhq0Og4lN7opF_Lb-dYp/view
" target="_blanck">
						Санітарно-технічне обладнання будівель
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1PN-gkJfQINhgj8I52ikRWB3BJrc8vPej/view
" target="_blanck">
						Будівельне матеріалознавство
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/18ViQdj_GymemmWljdV8ZWPU77gxrPh_u/view
" target="_blanck">
						Будівельна техніка
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rKMkcgoCprXpAd6LtfPwGhZgJ53zNyhM/view
" target="_blanck">
						Технологія будівельного виробництва
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/19HUHSzs8ie67UYbx0gsAZK3TPnN5ioOq/view
" target="_blanck">
						Організація будівельного виробництва
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1nEwUczRZLtOQ7cOkZhHvxSFRdh1Ivrk9/view
" target="_blanck">
						Опір Матеріалів
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1Lscy6o3s0X44NXMjt75bY5RHfWArvaMJ/view
" target="_blanck">
						Економіка будівництва
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1fQ0fovno0itS8sUzEByptG6nOmwiDw2Z/view?usp=sharing" target="_blanck">
						Будівництво та будівельні споруди
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1fScDCG3rKrhWedKZ3pvAVnzI1F1800JP/view?usp=sharing" target="_blanck">
					Архітектура та будівництво
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ff9XmjX1cchqxbjRJwLpJSKPdLN516ja/view?usp=drive_link" target="_blanck">
					English for business communication
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1GEgvL8rkylJeEQoWh5d4CLZBMJVV-BFI/view
" target="_blanck">
						Вища математика
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/14Lqyzmsv4Ocm1y7YnHq9mntCM_Da4SLb/view
" target="_blanck">
						Показникова та логарифмічна функція
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://surl.li/zpuezg" target="_blanck">
						Основи охорони праці
					</a>
				</h4>
				<br />
				<h4>				
					<a href="http://surl.li/otpekq" target="_blanck">
						Соціологія
					</a>
				</h4>
				<br />
				<h4>				
					<a href=" https://westudents.com.ua/knigi/627-flosofya-petrushenko-vl.html" target="_blanck">
						Філософія
					</a>
				</h4>
				<br />
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Library;