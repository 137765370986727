import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const Projects = () => {
	return (
		<article>
			<Header />

			<Pattern title="Erasmus+">
			</Pattern>

			<Partners />

			<Footer />
		</article>
	)
};

export default Projects;
