import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Plan = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="план роботи училища">

			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1sOMXbGhUlAPv-wtWDEyi-vOpqD2m-V-A/view?usp=sharing" target="_blanck">
					План роботи ДПТНЗ "ЛВПУКТБ" на 2024 -2025 рік
					</a>
				</h4>	
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Plan;