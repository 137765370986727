import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const Psychologist = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Практичний психолог">
			<br />
				<h4>Холод Світлана Федорівна</h4>
				<br />
				<h4>+38 (063) 872 37 43</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1NGAGgpLSMu9rx13YAZx3-FLRc9kLkO7s/view?usp=drive_link" target="_blanck">
                        План роботи практичного психолога ЛВПУ КТБ на 2024-2025 р.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1XY9R5G2a4XH5EnqpsAV0E1RGWNSI9RiG/view?usp=drive_link" target="_blanck">
					Положення про психологічну службу у системі освіти України.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/172VmJLTAjG4oHSrNuqHpn8f6ElZG0nrK/view?usp=drive_link" target="_blanck">
					Вивчення ставлення учнів до здорового способу життя
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/10yqwHSYIPzvyegw5E6gQPLKsi7w_EkFt/view?usp=drive_link" target="_blanck">
					Психологічна діагностика. Моніторинг обдарованості 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1sMXj4eyeKFCefKDUnKnsCW2SL9zIZwDF/view?usp=drive_link" target="_blanck">
					Психологічно-діагностична робота. Значущість сили волі у процесі навчання і здобутті професії 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ltAetbw6oUwsrX_xOlm0qTRdkNkbZnLz/view?usp=drive_link" target="_blanck">
					Узагальнені матеріали моніторингового дослідження серед учнів з питань соціальної адаптованості до нового колективу 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1VdhRrXAjLlIkd3wYIo_vDgn_tl1WGY0R/view?usp=drive_link" target="_blanck">
					Анкета. Психологічна діагностика (булінг)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/14WzrLHmswGb8fI8VBUVtbSulnm5VDEzU/view?usp=sharing" target="_blanck">
					Узагальнені матеріали соціологічного опитування серед здобувачів освіти щодо якості викладання у ДПТНЗ "ЛВПУ КТБ"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1wI7Ps_GB9G5n4X0-VLFJR-Xyk_jDpbqx/view?usp=drive_link" target="_blanck">
					Корекційно-відновлювальна та розвивальна робота (кібербулінг)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1Xe6N5U-R5cTAHlbctqoePWR_SkEVzEiX/view?usp=drive_link" target="_blanck">
					Корекційно-відновлювальна та розвивальна робота (булінг)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13hACZ2LyVLyTlPqPHVlQqTluhvIbrNZg/view?usp=drive_link" target="_blanck">
					Година спілкування на тему: "Емоції під контролем: три способи подолати тривогу" 
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Psychologist;