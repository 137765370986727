import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Monitoring = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='моніторинг якості освіти'>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/11NXmvm1eCUsICXfYhGm1FL849_cniw5y/view?usp=drive_link">						
					Звіт про самоаналіз освітньої діяльності закладу з професій 4113 Оператор з обробки інформації та програмного забезпечення
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1MNw5HMAExe9Cf9fieY463vroHCMIgk0K/view?usp=drive_link" target="_blanck">						
						Наказ про підготовку та проведення самоаналізу освітньої діяльності училища з професій: 4113 Оператор з обробки інформації та програмного забезпечення, 7212 Електрогазозварник 
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1AWZ4CniscaDPyAoufnmvC07ePr4xaCNc/view?usp=share_link" target="_blanck">						
						Наказ про введення в дію Положення про внутрішню систему забезпечення якості освіти ДПТНЗ "ЛВПУ КТБ"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1LzMM_8V8Ytimp33P6OP7t95zWo3yWYJO/view?usp=share_link" target="_blanck">						
						Наказ про самооцінювання та створення робочої групи
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1LOMeAP2VV7ZD9Wk1YuaY5ctoyxK7P-nq/view" target="_blanck">						
					Положення про внутрішню систему забезпечення якості освіти
					</a>
				</h4>			
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Monitoring;
