import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Specialties = () => {
	// const languageId = 0;
	
	// const articleList = props.articles[languageId].map(({ image, name, middleName, text }, index) => (
		// <li className="team-articles-item" key={index}>
			// <div className="team-articles-item-left">
				// <img className="team-articles-item-image" src={image} width="125" height="175" alt="Avatar" />
			// </div>
			
			// <div className="team-articles-item-right">
				// <h4 className="team-articles-item-right-title">{name}</h4>
				// <h4 className="team-articles-item-right-title">{middleName}</h4>
				// <p className="team-articles-item-right-text">{text}</p>
			// </div>
		// </li>
	// ));
				
	return (
		<article className="specialties">
			<Header />
				ok
			<Footer />
		</article>		
	)
};

export default Specialties;