import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Partners from "../_commonComponents/Partners/Partners";
import Pattern from "../_commonComponents/Pattern/Pattern";


const CourseDroneOperator = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Оператор Дронів БПЛА">
				<br />
				<h4>				
					<a href="" target="_blanck">
						Індивідуальний
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="" target="_blanck">
						Управління БПЛА
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="" target="_blanck">
						Основи будівельного та аграрного спостереження
					</a>
				</h4>	
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default CourseDroneOperator;