import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Zfpo = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='ЗФПО'>
				
			<br />
				<h4>				
					<a href="https://mon.gov.ua/static-objects/mon/sites/1/Fakhova%20peredvyshcha%20osvita/Zatverdzheni.standarty/2021/11/18/192-Budivn.tsyvil.inzhener.18.11.pdf" target="_blanck">
					Будівництво та цивільна інженерія
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://mon.gov.ua/static-objects/mon/sites/1/Fakhova%20peredvyshcha%20osvita/Zatverdzheni.standarty/2023/01/27/274-Avtomobilnyy.transport-82-27.01.2023.pdf" target="_blanck">
					Автомобільний транспорт_ Стандарт фахової передвищої освіти 2023 р.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1hwOemi2JjINIxqZVvt-SRBVKeOQE0yMO/view?usp=sharing" target="_blanck">
                        Стратегія розвитку закладу освіти
					</a>
				</h4>
                <br />
				<h4>				
					<a href="https://drive.google.com/file/d/1p20_XLCXlhZtOY2lO87WPtl07qHg5yAY/view?usp=sharing" target="_blanck">
                        Звіт керівника про реалізацію стратегії ДПТНЗ ЛВПУКТБ у сфері фахової передвищої освіти за 2021-2022 рік.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1tG5JUtsSbU20IUodzdsknGxuNVxW83tX/view" target="_blanck">
					Стратегія розвитку кадрового потенціалу
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1oHuZkvYf3v46hwwBXw8IrDU4zQAd13Bz/view?usp=sharing" target="_blanck">
					Наказ про створення Відділення з підготовки фахових молодших бакалаврів
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1jvj1nhzM67rIsOXZsvL3TgGr3t3ItFW4/view?usp=sharing" target="_blanck">
					Положення про Відділення з підготовки фахових молодших бакалаврів
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Zfpo;
