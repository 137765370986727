import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Sylabuses = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='силабуси'>

			<br />
				<h4>				
					<a href="https://drive.google.com/drive/folders/1Cr7Dy84y0V_m45QSkXdLaSyTzNZp0N6o?usp=sharing" target="_blanck">
					Силабуси_Архітектура та будівництво
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/drive/folders/1stgwGUPrhVGq4Fzb2y0HGOYGhlvB95RK?usp=sharing" target="_blanck">
					Силабуси_Обслуговування та ремонт автомобілів і двигунів
					</a>
				</h4>

			
			</Pattern>
			
			<Partners /> 
			
			<Footer />
		</article>
	)
};

export default Sylabuses;
