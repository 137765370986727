export const PHONE = {
	imgPath: 'image/phone-icon.svg',
	number: '+380 980 642 185'
};

// Header menu
export const NAV_LIST = [
	[
		{
			menuTitle: 'Головна',
			url: '/'
		},
		{
			menuTitle: 'про нас',
			items: [
				{
					title: 'історія закладу',
					url: '/history'
				},
				{
					title: 'фотогалерея',
					url: '/gallery'
				}
			]
		},
		{
			menuTitle: 'Вступникам',
			items: [
				{
					title: 'Вступна кампанія',
					url: '/compaign'
				},
				{
					title: `комп'ютерний напрям`,
					url: '/professions-1'
				},
				{
					title: `будівельний напрям`,
					url: '/professions-2'
				},
				{
					title: `автомобільний напрям`,
					url: '/professions-3'
				},
				{
					title: 'правила прийому',
					url: '/admission-rules'
				},
				{
					title: 'Приймальна комісія',
					url: '/admission-commitee'
				},
				{
					title: 'Заява онлайн',
					url: '/online-application'
				}
			]
		},
		{
			menuTitle: 'здобувачам освіти',
			items: [
				{
					title: 'правила внутрішнього розпорядку',
					url: '/inside-rules'
				},
				{
					title: `права та обов'язки здобувачів освіти`,
					url: '/rights-and-duties'
				},
				{
					title: 'правила поведінки здобувача освіти',
					url: '/rules-of-behavior'
				},
				{
					title: 'графік освітнього процесу',
					url: '/educational-process-chart'
				},
				{
					title: 'центр професійної кар`єри',
					url: '/school-breaktime'
				},
				{
					title: 'критерії оцінювання',
					url: '/evaluation-criteria'
				},
				{
					title: 'каталог вибіркових дисциплін',
					url: '/disciplines-catalog'
				},
				{
					title: 'Курси оператора БПЛА',
					url: '/course-operator-bpla'
				},
				{
					title: 'гуртки',
					url: '/exracurriculas'
				},
				{
					title: 'учнівське самоврядування',
					url: '/student-goverment'
				}
			]
		},
		{
			menuTitle: 'Освітній процес',
			items: [
				{
					title: 'план роботи училища',
					url: '/plan'
				},
				{
					title: 'навчально-виробнича діяльність',
					url: '/practice-activities'
				},
				{
					title: 'навчально-практичний центр',
					url: '/practice-center'
				},
				{
					title: 'виховна робота',
					url: '/education-work'
				},
				{
					title: 'методична робота',
					url: '/methodical-work'
				},
				{
					title: 'педагогічна рада',
					url: '/pedagogical-council'
				},
				{
					title: 'атестація',
					url: '/attestation'
				},
				{
					title: 'підвищення кваліфікації',
					url: '/professional-development'
				},
				{
					title: 'кадрове забезпечення ФМБ',
					url: '/personal-support'
				},
				{
					title: 'матеріально-технічне забезпечення ФМБ',
					url: '/material-technical-support'
				},
				{
					title: 'школа молодого педагога',
					url: '/young-pedagog-school'
				},
				{
					title: 'школа передового педагогічного досвіду',
					url: '/best-practics-pedagog-school'
				},
				{
					title: 'циклові комісії',
					url: '/commissions'
				},
				{
					title: 'мова освітнього процесу',
					url: '/education-language'
				},
				

			]
		},
		{
			menuTitle: 'новини',
			url: '/news'
		},
		{
			menuTitle: 'колектив',
			items: [
				{
					title: 'адміністрація',
					url: '/administrations'
				},
				{
					title: 'бухгалтерія',
					url: '/accounting'
				},
				{
					title: 'викладачі',
					url: '/teachers'
				},
				{
					title: 'майстри виробничого навчання',
					url: 'masters'
				},
				{
					title: 'юрисконсульт',
					url: '/urist'
				},
				{
					title: 'правила внутрішнього трудового розпорядку',
					url: '/personal'
				},
				
				
			]
		},
		{
			menuTitle: 'безпека життєдіяльності',
			url: '/life-safetly'
		},
		{
			menuTitle: 'публічна інформація',
			items: [
				{
					title: 'статут',
					url: '/regulation'
				},
				{
					title: 'ліцензії',
					url: '/licenses'
				},
				{
					title: 'свідоцтва',
					url: '/certificats'
				},
				{
					title: 'положення',
					url: '/thesis'
				},
				{
					title: 'силабуси',
					url: '/sylabuses'
				},
				{
					title: 'освітні програми',
					url: '/educational-programs'
				},
				{
					title: 'робочі програми',
					url: '/work-programs'
				},
				{
					title: 'ЗФПО',
					url: '/zfpo'
				},
				{
					title: 'ліцензування',
					url: '/licensing'
				},
				{
					title: 'ліцензований обсяг',
					url: '/licensed-amount'
				},
				{
					title: 'моніторинг якості освіти',
					url: '/monitoring'
				},
				{
					title: 'кодекс доброчесності',
					url: '/codex'
				},
				{
					title: 'наглядова рада',
					url: '/review-rada'
				},
				{
					title: 'звітність',
					url: '/reporting'
				},
				{
					title: 'послуги',
					url: '/service'
				},
				{
					title: 'вакансії',
					url: '/vacancy'
				},
				{
					title: 'Офіційна інформація',
					url: '/official-information'
				}
			]
		}
	]
];

// Left menu
export const NAV_SECOND_LIST = [
	[
		{
			menuTitle: 'Про нас',
			items: [
				{
					title: 'Історія закладу',
					url: '/history'
				},
				{
					title: 'фотогалерея',
					url: '/gallery'
				}
			]
		},
		{
			menuTitle: 'колектив',
			items: [
				{
					title: 'Адміністрація',
					url: '/administrations'
				},
				{
					title: 'Бухгалтерія',
					url: '/accounting'
				},
				{
					title: 'Викладачі',
					url: '/teachers'
				},
				{
					title: 'Майстри виробничого навчання',
					url: '/masters'
				},
				{
					title: 'Юрисконсульт',
					url: '/urist'
				}
			]
		},
		{
			menuTitle: 'співпраця',
			items: []
		},
		{
			menuTitle: 'International Cooperation',
			url: '/international-cooperation',
			items: [
				{
					title: 'Partners',
					url: '/our-partners'
				},
				{
					title: 'Projects',
					url: '/projects'
				}
			]
		},
		{
			menuTitle: 'протидія булінгу',
			url: '/booling'
		},
		{
			menuTitle: 'служба підтримки',
			items: [
				{
					title: 'Практичний психолог',
					url: '/psychologist'
				},
				{
					title: 'Педагог соціальний',
					url: '/social-teacher'
				},
				{
					title: 'Сестра медична',
					url: '/nurse'
				}
			]
		},
		{
			menuTitle: 'covid-19',
			url: '/covid'
		},
		{
			menuTitle: 'бібліотека',
			url: '/library'
		},
		{
			menuTitle: 'гуртожиток',
			url: '/dormitory'
		},
		{
			menuTitle: 'їдальня',
			url: '/dining-room'
		},
		{
			menuTitle: 'корисні посилання',
			url: '/useful-links'
		}
	]
];
