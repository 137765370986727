export const TITLE = {
	title: ['історія нашого закладу'],
	imgPath: 'image/history/picture-title.png'
};

export const ARTICLES = [
	[
		{
			title: 'Заснування училища',
			text: `Історія створення і розвитку Львівського вищого професійного училища комп’ютерних технологій та будівництва
				охоплює більше ніж  50 років. У 1965 році у Львові розпочався інтенсивний ремонт старого житлового фонду,
				пам’яток архітектури, збільшився об’єм ремонтних робіт, рухомого складу міського електротранспорту.
				Для виконання цих завдань потрібна була велика кількість висококваліфікованих робітників.
				З цією метою було створено міське професійно-технічне училище №48 (комунальників). Спочатку в училищі
				навчалося 200 учнів, підготовка здійснювалася за 4-5 спеціальностями. Освітній процес відбувався  у старих корпусах.`
		},
		{
			title: 'новий сучасний корпус',
			text: `Знаковим для навчального закладу став 1975 рік, адже саме тоді   училище продовжило свою освітню діяльність у 
				новому сучасному корпусі на вулиці Авраама Лінкольна. Навчальний корпус побудований за типовим
				проектом на 800 місць. Це дозволило покращити навчальну, матеріально-технічну бази, відкрити низку
				нових спеціальностей, що суттєво позначилося на якості знань  випускників.`
		},
		{
			title: 'реорганізація навчального закладу',
			text: `Згідно з наказом №68 від 22 березня 1976 року Львівського обласного управління професійно-технічної
				освіти МПТУ №48 перейменоване у Міське середнє професійно-технічне училище №48,  у 1984 році – в СПТУ №48 м.Львова.
				Відтоді учні  водночас опановують фах  і здобувають  повну загальну середню освіту. Наказом №322
				від 10.09.1999 Міністерства освіти і науки України професійно-технічне училище № 48 було реорганізоване
				у вище професійне училище № 48 м. Львова – єдине на той час у регіоні училище, яке почало здійснювати
				підготовку висококваліфікованих робітників для житлово-комунального господарства. 
				За період 1965 – 2004 рр. випускниками училища стали 12000 кваліфікованих робітників. Першого березня
				2007 року ВПУ N 48 м. Львова перейменовано в Державний професійно-технічний навчальний заклад 
				"Львівське вище професійне училище комп'ютерних технологій та будівництва".`
		},
		{
			title: 'училище в наші часи',
			text: `Cьогодні – це сучасний професійно-технічний навчальний заклад, який здійснює підготовку 
				понад 600 висококваліфікованих робітників і фахових молодших бакалаврів. Упродовж років багато зусиль 
				направлено на підготовку кваліфікованих робітників за інтегрованими комплексними професіями, що значно 
				підвищує перспективу працевлаштування наших випускників.`
		}
	]
];

export const PICTURES = [
	'image/history/picture-1.png',
	'image/history/picture-2.png',
	'image/history/picture-3.png',
	'image/history/picture-4.png',
	'image/history/picture-5.png'
];