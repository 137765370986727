import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const PersonalSupport = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='кадрове забезпечення ФМБ'>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ULECrQeI7XHjHs_jAEzUeszKB4GMUZ50/view?usp=sharing" target="_blanck">
					Кадрове забезпечення Автомобільний транспорт
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rg1gdwBiFCQ2xAak2R1RUU9xynDPrMYp/view?usp=sharing" target="_blanck">
					Кадрове забезпечення Будівництво та цивільна інженерія
					</a>
				</h4>
				
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default PersonalSupport;