import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NavSecond from '../_commonComponents/NavSecond/NavSecond';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const Personal = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="правила внутрішнього розпорядку">
			<br/>
			<h4><a href='https://drive.google.com/file/d/19RqDjArySe4N2dikdJF9SEt-0Qe6fKNy/view?usp=drive_link'>Правила внутрішнього трудового розпорядку</a></h4>
			
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Personal;