export const TITLE = [
	'оголошення'
];

export const ARTICLES = [
	[
		`+ Андрій Пилявка, учень групи №275, зайняв ІІ місце в номінації «Читці гумористичної, сатиричної прози 
		та поезії» на обласному фестивалі-конкурсі дитячої та юнацької художньої творчості «Гумор і сміх об’єднує всіх – 2021».`,
		`+ Заходи на період карантинних обмежень.`,
		`+ Наказ від 27.08.2020 № 442 Про дотримання протиепідеміологічних вимог у гуртожитках університету в період карантину у
			зв'язку з поширенням коронавірусної хвороби (COVID-19).`,
		`+ Наказ від 27.08.2020 № 443 Про відповідальну особу за організацію протиепідемічних заходів в університеті на період карантину.`,
		`+ Увага! Вакцинація працівників сфери освіти.`,
		`+ Заходи на період карантинних обмежень.`,
		`+ Наказ від 27.08.2020 № 442 Про дотримання протиепідеміологічних вимог у гуртожитках університету в період карантину у зв'язку
			з поширенням коронавірусної хвороби (COVID-19).`,
		`+ Наказ від 27.08.2020 № 443 Про відповідальну особу за організацію протиепідемічних заходів в університеті
			на період карантину.`,
		`+ Наказ від 30.04.2021 № 297 Про організацію освітнього процесу з 11 травня 2021 року.`
	]
];

export const ARTICLES_PROPS = [
	{
		allAdvertisements: 'Всі оголошення'
	}
];