import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const LifeSafetly = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="безпека життєдіяльності">
				<br />

				<h3>
					УВАГА!
				</h3>

				<br />

				<h4>
					З вересня 2022-2023 н.р.ведеться -
					<ul>
						<li>Зовнішнє відеоспостереження - при вході у гуртожиток та навчальний корпус;</li>
						<li>Внутрішнє відеоспостереження - у коридорах навчального корпусу.</li>
					</ul>
				</h4>

				<br />
				
				<h4>
					<a href="https://drive.google.com/file/d/1IDFAhK-FnAVwOINlIG3_hhjhUauiThi7/view?usp=share_link">
						Алгоритм дій викладачів та майстрів виробничого навчання при оголошенні сигналу "Повітряна тривога"
					</a>
				</h4>

				<br />

				<h4>
					<a href="https://drive.google.com/file/d/1HmuxO8h6AVFaM8xyJAXW0NUWSsDSwoxr/view">
						Алгоритм дій для здобувачів при оголошенні сигналу "Повітряна тривога"
					</a>
				</h4>

				<br />

				<h4>
					<a href="https://drive.google.com/file/d/1ngwsX8GorkTOZDCC_chdQm-eVnQ92yhU/view">
						Алгоритм дій чергових та сторожів при оголошенні сигналу "повітряна тривога"
					</a>
				</h4>

				<br />
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default LifeSafetly;