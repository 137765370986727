import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Licenses = () => {	
	return (
		<article className="licenses">
			<Header />
			
			<Pattern title='Ліцензії ПТО'>
				<br />
				<h4>				
					<a href="https://registry.edbo.gov.ua/university/1920/professions/" target="_blank">РЕЄСТР СУБ'ЄКТІВ ОСВІТНЬОЇ ДІЯЛЬНОСТІ</a>
				</h4>
				<br />
				<h4>				
					<a href="https://mon.gov.ua/static-objects/mon/sites/1/pravo-diyalnosti/2019/11/27/vpukomptekhnfakhovoiperedvishchoi33.pdf" target="_blank">Відомості щодо права здійснення освітньої діяльності
					у сфері фахової передвищої освіти ДПТНЗ "ЛВПУ КТБ"</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1zKT24WdspvZH6IP5M2kbJH7FDJhbo5T8/view?usp=drivesdk" target="_blank">Ліцензія МОН України ФМБ (молодший спеціаліст)</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/17fgrGvmSj60CD3hNY0zDGhnvcQb0HNts/view?usp=drivesdk" target="_blank">Ліцензія МОН України на освітню діяльність у сфері ПТО.</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/180Dh0mhsdzQTvtYQmxaRZcwEr0LlBsQG/view?usp=drivesdk" target="_blank">Наказ про атестацію у сфері середньої освіти у ДПТНЗ ЛВПУКТБ </a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1bIsxcYXqf_9rvwTROkADYFbly3n84kem/view?usp=drivesdk" target="_blank">Наказ МОН України про переоформлення ліцензії у сфері вищої освіти у ДПТНЗ ЛВПУКТБ</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1r1fsJoK1iGbpC3-o5xzJsUVOCjYmQJD-/view?usp=share_link" target="_blank">Сертифікат про акредитацію ФМБ Автомобільний транспорт</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1pNRA0CjJmPInOHX3Uv9-kfSv9cJi2Hk8/view?usp=share_link" target="_blank">Сертифікат про акредитацію ФМБ Будівництво та цивільна інженерія</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Licenses;
