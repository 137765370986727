import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const MaterialTechnicalSupport = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='Матеріально-технічне забезпечення ФМБ'>
			
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1DSn2a93yLFkt1Tg8-Hivhi0YXRkGzXR-/view?usp=drive_link" target="_blanck">						
					Будівництво та цивільна інженерія
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1-WlnaerZuSw-LNk2OXq4aKoOt0JhmwZ8/view?usp=drive_link" target="_blanck">						
					Автомобільний транспорт
					</a>
				</h4>		
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default MaterialTechnicalSupport;