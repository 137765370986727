import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Codex = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='кодекс доброчесності'>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13a5Lpd4hGCNZDA05gOSppZJw610fl5lH/view?usp=drive_link" target="_blanck">
						Наказ про введення в дію положення про академічну доброчесність учасників освітнього процесу ДПТНЗ ЛВПУКТБ
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13dOaKOadUOU0ez7_JTr_3v__ljZhst3E/view?usp=drive_link" target="_blanck">
						Положення про академічну доброчесність учасників освітнього процесу ДПТНЗ ЛВПУКТБ
					</a>
				</h4>
				
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Codex;
