import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const EducationLanguage = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='мова освітнього процесу'>
				<br />
				<h4>				
				Закон України «Про освіту» від 05.09.2017 № 2145-VIII Стаття 7. Мова освіти
				</h4>
				<br />
				<h4>				
				Закон України  «Про фахову передвищу освіту»  від 06.06.2019 № 2745-VIII Стаття 46. Мова освітнього процесу
				</h4>
				<br />
				<p>				
				1. Мовою освітнього процесу в Комунальному закладі Львівської обласної ради «Львівське вище професійне училище комп’ютерних технологій та будівництва» (далі - КЗЛОР «ЛВПУКТБ») є державна мова. Засади мовної політики у КЗЛОР «ЛВПУКТБ» визначаються Законом України «Про освіту».
				</p>
				<br />
				<p>				
				2. Держава гарантує кожному громадянинові України право на здобуття загальної середньої, професійної (професійно-технічної), фахової передвищої фахової освіти державною мовою в державних і комунальних закладах фахової передвищої освіти.
				</p>
				<br />
				<p>				
				3. КЗЛОР «ЛВПУКТБ» забезпечує обов’язкове вивчення державної мови в обсязі, що дає змогу провадити професійну діяльність в обраній галузі з використанням державної мови.
				</p>
				<br />
				<p>				
				Особам, які належать до корінних народів, національних меншин України, іноземцям та особам без громадянства створюються належні умови для вивчення державної мови.
				</p>
				<br />
				<p>				
				4. Держава сприяє вивченню мов міжнародного спілкування, насамперед англійської мови, у державних і комунальних закладах фахової передвищої освіти.
				</p>
				<br />
				<p>				
				5. У закладах фахової перед вищої освіти відповідно до освітньо-професійної програми можуть викладатися одна або декілька дисциплін двома чи більше мовами - державною мовою, англійською мовою, іншими офіційними мовами Європейського Союзу, забезпечуючи при цьому здатність здобувачів фахової передвищої освіти продемонструвати результати навчання відповідної дисципліни державною мовою. Перелік іноземних мов, якими здійснюється викладання навчальних дисциплін, визначається закладом фахової передвищої освіти.
				</p>
				<br />
				<p>				
				6. За бажанням здобувачів фахової передвищої освіти та за наявності відповідних умов заклади фахової передвищої освіти створюють можливості для вивчення ними мови корінного народу, національної меншини України як окремої дисципліни в обсязі, що дає змогу провадити професійну діяльність у вибраній галузі з використанням цієї мови.
				</p>
				<br />
				<p>				
				7. Для навчання іноземців, осіб без громадянства, які постійно проживають в Україні, а також осіб, які визнані біженцями в Україні, осіб, які потребують додаткового або тимчасового захисту, осіб, яким надано статус закордонного українця, які перебувають в Україні на законних підставах, та здобувають фахову передвищу освіту за кошти фізичних або юридичних осіб, можуть створюватися окремі групи або розроблятися індивідуальні навчальні плани, що передбачають навчання іноземною мовою. При цьому заклади фахової передвищої освіти забезпечують вивчення такими особами державної мови як окремої навчальної дисципліни.
				</p>
				<br />
				<p>				
				На зазначених осіб не поширюються вимоги частини першої цієї статті.
				</p>
				<br />
				<p>				
				8. Атестація здобувачів фахової передвищої освіти КЗЛОР «ЛВПУ КТБ» проводиться державною мовою.
				</p>
				
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default EducationLanguage;
