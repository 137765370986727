import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const SchoolBreaktime = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='центр професійної кар`єри'>
				<h4>
					<a href="https://drive.google.com/file/d/12pBEjPX4HU8MPpi7HaHxc45kIK-aDdsh/view?usp=share_link" target="_blanck">
						Положення про центр професійної кар'єри
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default SchoolBreaktime;