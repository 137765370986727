import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Exracurriculas = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="гуртки">

			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1uRIinh8kYILo_egtpNfExgYZ36NHjquz/view?usp=drive_link" target="_blanck">Розклад занять гуртків КЗ ЛОР "ЛВПУКТБ" І семестр 2024 н.р.</a>
			</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Exracurriculas;