import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Thesis = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='положення'>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/137BLytcK-IoT_C2hHLihLZ1pFH2GyMFA/view" target="_blanck">
					Положення про організацію освітнього процесу на рівні фахової передвищої освіти у ДПТНЗ ЛВПУ КТБ
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1LOMeAP2VV7ZD9Wk1YuaY5ctoyxK7P-nq/view" target="_blanck">
					Положення про порядок розроблення, затвердження, оновлення та моніторинг ОПП
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1kDm3ETVSNXBKB9GFjDJajeH7D4y4djOO/view" target="_blanck">
					Положення про порядок вибору вибіркових дисциплін
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1jiNXjwppBeHoPWgkZTlQ0QmV_RlOJ7hp/view" target="_blanck">
					Положення про академічну мобільність
					</a>
				</h4>
					<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1T8YSglT4sak5KQ74yKQ6IdijVq1SpOtf/view" target="_blanck">
					Положення про гаранта освітньо-професійної програми
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/175EEQlZtr_-g1WXft2yfMNvsNs15Bl-e/view?usp=sharingnp" target="_blanck">
					Положення про Державний професійно-технічний навчальний заклад "Львівське вище професійне училище комп'ютерних технологій та будівництва"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1GfPdDDUWW64Z42HA3zREhG4Y_kvIR-Wx/view" target="_blanck">
					Положення про студентоцентризм
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1OtSIkC00Bhu84qdQ-O7RtsqKqvZkPtA8/view" target="_blanck">
					Положення про опитування
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1uN6GdiqlxQIa-g64EcWdui8BHxzFvi3_/view" target="_blanck">
					Положення про робочу програму навчальної дисципліни
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/11QaZaZxP33mVh9spfLhMJYDKNz45inNG/view" target="_blanck">
					Положення про практичне заняття
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1V_wY-CrR6wjkcJ2cCbV3LrvGLUYw8m40/view" target="_blanck">
					Положення про стейкхолдерів
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1NM3iV3e1YL67qnT19w08E7CHWCLRtW6o/view" target="_blanck">
					Положення про апеляційну комісію
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1c3oWR7RcaJTFJNeOXNOkMDhC0f1urjtQ/view" target="_blanck">
					Положення про курсові роботи 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1mk9sxB_wVuO0WCvUF_tGeq3tBy4UFZDY/view" target="_blanck">
					Положення про критерії оціювання 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/197EL979A65EJzeBYKdjuMm0snWgml1Ep/view" target="_blanck">
					Положення про моніторинг якості освіти 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/10lZtpdqfIPbRTFmEmqlHb8UEhFVJZbiR/view" target="_blanck">
					Положення про силабуси навчальних дисциплін 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1XBVeYhtjqbBK2jBEKghArrebPMv-_Npf/view" target="_blanck">
					Індивідуальний навчальний план здобувача освіти 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1mH_49wFzLHGonsbP_gPaS1AUxnvw72Aj/view" target="_blanck">
					Положення про навчально-методичне забезпечення  
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/16JbA3txJoW9MZ0uR-QxCzM7LfxDjh9u_/view" target="_blanck">
					Положення про практичну підготовку на рівні фахвої передвищої освіти  
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rjQi9aaDWVSoFuvvAACkyVvAnzh6VkXR/view" target="_blanck">
					Положення про семестровий контроль  
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1hMMG7NiUGsjpLos20ltmFifHri332iBm/view?usp=drive_link" target="_blanck">
					Положення про навчально-виробничу діяльність
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1-pM-pGOrn0N8JgUEnxYoXy9OkUw5Ws4F/view?usp=drive_link" target="_blanck">
					Положення про процедуру визнання результатів формальних/неформальних  
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1VEjZYPSMPw5ZxNA0Q43GBdCTfSZukaZz/view?usp=sharing" target="_blanck">
					Положення про плагіат 
					</a>
				</h4>
				<br />
				<br />
				<h3>				
						Положення по виховній роботі
				</h3>
								<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1K2poYvEZSnJNiS6XkPzPY_6KzyclL5yW/view?usp=drive_link" target="_blanck">
					Положення про профорієнтаційну роботу
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1meHoK5w89PDdS1SEQQQGZkV3LKJXxipe/view?usp=drive_link" target="_blanck">
					Положення про протидію булінгу
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1dDedQCK6BMo9YVxQ78DvQ7Z4bI6ayegg/view?usp=drive_link" target="_blanck">
					Положення про врегулювання конфліктних сиуацій
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1P-lyXCJoKpppzdRjNHbjGaN58yCkOMuE/view?usp=drive_link" target="_blanck">
					Положення про класного керівника
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1YutDtjaIWJUnNBrywSmcgHoF0aBM6Ryt/view?usp=drive_link" target="_blanck">
					Положення по учнівське самоврядування
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1-g7Xab7YRfa6csoXCcgSv1E2Bj7EbYGU/view?usp=drive_link" target="_blanck">
					Положення про старосту навчальної групи
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13DlouUk_hienXqF8p9X-kmIXhcA19pp6/view?usp=drive_link" target="_blanck">
					Положення про учнівський гуртожиток
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1cR4MN-ZjldP6uzCPHsiSbpgMlsn1K_2E/view?usp=sharing" target="_blanck">
					Положення про УРГ гуртожитку
					</a>
				</h4>
				
			</Pattern>
			

			<Partners />
			
			<Footer />
		</article>
	)
};

export default Thesis;
