import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';
import './MethodicalWork.css';

const MethodicalWork = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="методична робота">
				
			<br />
				<h4>Науково-методична тема над якою працює заклад освіти:</h4>

				<h5>«Створення  сучасного освітнього середовища  для формування професійної, соціальної та особистісної компетентностей у здобувачів освіти закладу професійної освіти». Термін реалізації – 2022-2027 н.р. </h5>
								
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1z5K6eAFOwY_PdzDLc1-18uZEj83vsnYH/view?usp=sharing" target="_blanck">						
					2024_2025_Наказ про організацію методичної роботи
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1jO2Q0eLf8gCD00LhqkiyIE6my-MRnTfT/view?usp=sharing" target="_blanck">						
					Положення про методичну роботу в ДПТНЗ ЛВПУКТБ
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rZkFTnW-lBD6_6IVCjnrvRs-YrOConWA/view?usp=sharing" target="_blanck">						
					Положення  про методичний кабінет ДПТНЗ "ЛВПУКТБ"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13agPZiBhP2GzJLpFVfDPhtR_Oct-3Aq3/view?usp=drive_link" target="_blanck">						
					План методичної роботи ДПТНЗ "ЛВПУКТБ" на 2024/2025 навчальний рік
					</a>
				</h4>

				<br />
				<h4>МЕТОДИЧНА РАДА</h4>

				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1WqkU7gLioswhOqtw_xPv5lvMz9lUBSs1/view?usp=sharing" target="_blanck">						
					План роботи методичної ради на 2024-2025 н.р.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ulSrzlUTjiCZ3yWIIZbU2sxPypx6V7oW/view?usp=sharing" target="_blanck">						
					Протокол №1 від 02.09.2024
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1RoWzAQqtDbtt8DNTHV68xPFzsL50g4Mu/view?usp=sharing" target="_blanck">						
					План роботи методичної ради на 2023/2024 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1cbqZkMEFs_PC7bUwJuz15KYDUNEErGeB/view?usp=sharing" target="_blanck">						
					Протокол №1 від 30.08.2023
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1gbR1Ckhfhg5fLbSvFZAmmhiCRX_Ullhf/view?usp=sharing" target="_blanck">						
					Протокол №2 від 20.11.2023
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1IsbvozdqlLEaDIH0ArqYtEwxNXXD9INw/view?usp=sharing" target="_blanck">						
					Протокол № 3 від 05.02.2024
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1yCr6W12BBLSlGGyRmn-aPuwJxWXbCL_A/view?usp=sharing" target="_blanck">						
					Протокол №4 від 08.04.2024
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1yCr6W12BBLSlGGyRmn-aPuwJxWXbCL_A/view?usp=sharing" target="_blanck">						
					Протокол №5 від 24.06.2024
					</a>
				</h4>

				
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default MethodicalWork;