import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const YoungPedagogSchool = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Школа молодого педагога">
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/14tclLQ8BducNVcR_jmzN6py05rSAvKJS/view?usp=drive_link" target="_blanck">
					Положення про Школу молодого педагога
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1mUesJUDHXR45fmjLq8Ujm5fSz6uNoTiC/view?usp=drive_link" target="_blanck">
					2023 План роботи Школи молодого педагога
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rjvcF4e7b6J463rbe5WNZ23Ro0z7a2eZ/view?usp=drive_link" target="_blanck">
					2024 План роботи Школи молодого педагога
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1Tc1xPMjZX1bP8ekeHOXMEG6f9V9FtiqD/view?usp=sharing" target="_blanck">
					2023 Члени Школи молого педагога
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1Ip1411JUhzuN5l3l7MKDgexCx5hbvw80/view?usp=drive_link" target="_blanck">
					2024 Члени Школи молого педагога
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1oVLF5vX2L5oW7ZI0DzKdAOKlAK0gVSSq/view?usp=sharing" target="_blanck">
					План роботи ШМП на 2023-2024
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1VnHllaAFAODV_vO7_FJPVhCAqNXVoERK/view?usp=drive_link" target="_blanck">
					План роботи ШМП на 2024-2025
					</a>
				</h4>
				

			</Pattern>
			
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default YoungPedagogSchool;