import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Regulation = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title=''>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/18ZmH3xzpit8cgPW7g825gcnUfHaaMDFw/view?usp=drive_link" target="_blanck">
						Статут КЗЛОР "ЛВПУКТБ" (нова редакція) 2024 р.
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Regulation;
