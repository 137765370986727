import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const RecurringCommissions = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Циклові комісії">
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1CqDTBeM6z5uqr-UM0IMPZxwL_FwjbwO2/view" target="_blanck">
					Положення про циклові комісії у ДПТНЗ "ЛВПУ КТБ"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1kcaVwR33Q7U4SJ3y9qu638vnKHe94HGS/view?usp=sharing" target="_blanck">
					2024_Наказ про створення МК,ЦК
					</a>
				</h4>

			</Pattern>
			
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default RecurringCommissions;