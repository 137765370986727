import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const OfficialInformation = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Офіційна інформація">
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1FLmpS0LOOSkqQ8dxd3vbOyNZD_8ZfWnx/view?usp=drive_link" target="_blanck">
					Наказ щодо проведення правової експертизи документів закладу
					</a>
				</h4>
				<br />	
				<h4>Закупівлі за процедурою відкритих торгів з особливостями у 2023 р.</h4>
				<br />
				<h5>				
					<a href="https://gov.e-tender.ua/tender/ofisna-ta-kompyuterna-tekhnika/UA-2023-11-09-009687-a-personalni-kompyutery">
					Персональні комп'ютери
					</a>
				</h5>
				<br />
				<h5>				
					<a href="https://gov.e-tender.ua/tender/telekomunikacijne-obladnannya/UA-2023-11-07-014916-a-interaktyvna-panel">
					Інтерактивна панель
					</a>
				</h5>
				<br />
				<h5>				
					<a href="https://gov.e-tender.ua/tender/girnichodobuvne-obladnannya/UA-2023-11-09-009517-a-shtukaturna-stancziya">
					Штукатурна станція
					</a>
				</h5>
				<br />
				<h5>				
					<a href="https://gov.e-tender.ua/tender/telekomunikacijne-obladnannya/UA-2023-11-15-013720-a-interaktyvna-panel-v-komplekti">
					Інтерактивна панель у комплекті
					</a>
				</h5>	
			
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ngRqy3FskOh5So_dUHmEzZPrhLlRuB8J/view?usp=drive_link" target="_blanck">
					Результати вибору електронних підручників 2024
					</a>
				</h4>	
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1J7sn-qKG_mmovyrlrva-dHmJrfYftJOr/view?usp=drive_link" target="_blanck">
					Результати вибору електронних підручників 2023
					</a>
				</h4>	
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1z5IS8RS34A1RINu7qQ9-WreAqiBqbVRr/view?usp=sharing" target="_blanck">
						Електроенергія 2022
					</a>
				</h4>			
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1vaggkhYIbkgmZeg_YYwmJeU635IkAhbY/view" target="_blanck">
						Електроенергія 2023
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://gov.e-tender.ua/tender/naftoprodukti-ta-elektroenergiya/UA-2024-01-04-002694-a-elektrychna-enerhiya-z-postachannyam-ta-peredacheyu" target="_blanck">
						Закупівлі: Електроенергія 2024
					</a>
				</h4>	
				<br/>
				<h4>				
					<a href="https://docs.google.com/document/d/1x5kHr9s8TWBUaktzId4d_wCJHNfVBnZO/edit?usp=drive_link" target="_blanck">
						Електроенергія 2024
					</a>
				</h4>				
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default OfficialInformation;