import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const ProfessionalDevelopment = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='Підвищення кваліфікації'>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/14NULZHbKpyuZ1vXhSBSvwIvnNlVtwp16/view" target="_blanck">Положення про умови та порядок підвищення кваліфікації педагогічних (науково-педагогічних) прцівників ДПТНЗ ЛВПУКТБ</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1oxsK3QIUfud-hUDb3_DQps2vey_okeEK/view?usp=drive_link" target="_blanck">Підвищення кваліфікації від Google</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1eKPfL3oUSrDKOF6C5hXgb-yKgZUzwHpL/view?usp=sharing" target="_blanck">ЛОІППО замовлення 2025</a>
			</h4>
			<br/>
			<h4>
			<a href="https://docs.google.com/spreadsheets/d/1L9cOr5cr28LB0NynxSDx1HrBY5tsxISM/edit?usp=sharing&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">Регіональне замовлення перелік курсів ЛОІППО 2025</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1IRYJi9DnBfxbO20u6M4njgdg-7-8wiJI/view?usp=sharing" target="_blanck">Курси замовлення ЛННЦПТО І квартал 2025</a>
			</h4>
			<br/>
			<h4>
			<a href="https://zakon.rada.gov.ua/laws/show/800-2019-%D0%BF#Text" target="_blanck">Деякі питання підвищення кваліфікації педагогічних і науково-педагогічних працівників</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1uSjzTg1ZFfdhtIzJTKXsO8y-Eb-I_zXj/view?usp=sharing" target="_blanck">2024_Річний план підвищення кваліфікації педагогічних працівників</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/11zAldeBiwTYjMq4-_4swhWHlQeft24sx/view?usp=sharing" target="_blanck">2023_Річний план підвищення кваліфікації педагогічних працівників</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1IxGIFKM_yLo_R5fve_dwVYQMY8xolnf4/view?usp=sharing" target="_blanck">Перспективний план підвищення кваліфікації керівників</a>
			</h4>
				
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default ProfessionalDevelopment;