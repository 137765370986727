import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const AdmissionComitee = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Приймальна комісія">
			
			<br />
				<h4>
					<a href="https://drive.google.com/file/d/1cQ63RvudS9NHlTkzujqhFQ3Y1bcwdexO/view?usp=drive_link" target="_blanck">
						Рейтинговий список від 11 серпня 2023 року (Будівництво та цивільна інженерія)
					</a>
				</h4>
				<h4>
					<a href="https://drive.google.com/file/d/1cgt0LqGnlnwolbKatW4j8kOnykS_msmW/view?usp=drive_link" target="_blanck">
						Рейтинговий список від 11 серпня 2023 року (Автомобільний транспорт)
					</a>
				</h4>
				<br />
				<h4>
					<a href="https://www.facebook.com/profile.php?id=100020080413173" target="_blanck">
						Приймальна комісія
					</a>
				</h4>
				<br />
				<h4>
					<a href="https://drive.google.com/file/d/1HMUlKSxHMr5rHg5Jj4DsYZv3wne8o3ql/view?usp=drive_link" target="_blanck">
						Положення про приймальну комісію ДПТНЗ «ЛВПУ КТБ»
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default AdmissionComitee;