import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const ReviewRada = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='Наглядова рада'>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1SryJkLaPv_NT7uxHaR7TsVBqNOgAdQAN/view?usp=share_link" target="_blanck">
						Положення про Наглядову раду ДПТНЗ "ЛВПУ КТБ"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/19-2NTnGPcpcPEIkbt9fiw10PiHmXiaCJ/view?usp=share_link" target="_blanck">
						Наказ про створення Наглядової ради ДПТНЗ "ЛВПУ КТБ"
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default ReviewRada;
