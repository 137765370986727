import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const WorkPrograms = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='робочі програми'>
			
			
				<br />
				<h4>				
					<a href="https://drive.google.com/drive/folders/12F2VFj36uk4whI-_jwhDVDuaPbXKC8HA?usp=sharing" target="_blanck">
					Архітектура та будівництво
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/drive/folders/1KfXQgQR2DXxF6v2VN8VQZmYxg_J_iFwL?usp=sharing" target="_blanck">
					Автомобільний транспорт
					</a>
				</h4>


			</Pattern>

			
				
				
				

			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default WorkPrograms;
