import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Article from "../Article/Article";

import {INTERNATIONAL_COOPERATION_ARTICLE} from "../../constants/internationalCooperation";

const InternationalCooperation = () => {
    return (
        <article className="article">
            <Header/>

            <Pattern title="">
                <Article article={INTERNATIONAL_COOPERATION_ARTICLE}></Article>
            </Pattern>

            <Partners/>

            <Footer/>
        </article>
    )
};

export default InternationalCooperation;
